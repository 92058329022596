'use client';

import { QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import queryClient from 'src/services/queryClient';

import { POSTHOG } from '../config-global';
import AblyRealtimeProvider from './AblyProvider';

const isProdEnvironment = typeof window !== 'undefined' && window.location.host === 'trybe.fm';

export function PHProvider({ children }: PropsWithChildren) {
  const [postHogInitialized, setPostHogInitialized] = useState(false);
  const { user } = useAuthContext(); // Access the user from AuthContext

  useEffect(() => {
    if (isProdEnvironment && POSTHOG.publicKey && !postHogInitialized) {
      posthog.init(POSTHOG.publicKey, {
        api_host: POSTHOG.host,
        capture_pageview: false, // Disable automatic pageview capture
      });
      setPostHogInitialized(true); // Mark Posthog as initialized
    }
  }, [postHogInitialized, user]);

  const renderWithPostHogAndAbly = (
    <AblyRealtimeProvider>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </PostHogProvider>
    </AblyRealtimeProvider>
  );

  const renderWithAblyOnly = (
    <AblyRealtimeProvider>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AblyRealtimeProvider>
  );

  const renderWithoutAbly = (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );

  // Return based on the environment, posthog, and user conditions
  if (user) {
    if (isProdEnvironment && postHogInitialized) {
      return renderWithPostHogAndAbly;
    }
    return renderWithAblyOnly;
  }
  return renderWithoutAbly;
}
