'use client';

import Cookies from 'js-cookie';
import posthog from 'posthog-js';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import axios, { endpoints } from 'src/utils/axios';
import { getPictureToDisplay } from 'src/utils/picture';
import { StreamChat } from 'stream-chat';

import { sendPhoneVerificationCode, verifyPhoneCode } from '../../../api/auth';
import { useSupabase } from '../../../hooks/use-supabase';
import { User } from '../../../types';
import { handleDeviceToken } from '../../../utils/fcm';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  PHONE = 'PHONE',
  LOGOUT = 'LOGOUT',
  VERIFYPHONE = 'VERIFYPHONE',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
    chatToken: string;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    chatToken: string;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
    chatToken: string;
  };
  [Types.PHONE]: {
    phone: string;
  };
  [Types.VERIFYPHONE]: {
    phoneVerified: boolean;
  };
  [Types.LOGOUT]: undefined;
};

const client = StreamChat.getInstance('y7fsjd33c926');

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  chatToken: '',
  phone: '',
  phoneVerified: false,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      ...state,
      loading: false,
      user: action.payload.user,
      chatToken: action.payload.chatToken,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
      chatToken: action.payload.chatToken,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
      chatToken: action.payload.chatToken,
    };
  }
  if (action.type === Types.PHONE) {
    return {
      ...state,
      phone: action.payload.phone,
    };
  }
  if (action.type === Types.VERIFYPHONE) {
    return {
      ...state,
      phoneVerified: action.payload.phoneVerified,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const supabase = useSupabase();

  const fetchUserInfos = async (): Promise<User> => {
    const { data } = await axios.get(endpoints.auth.me);
    return data;
  };

  const getChatToken = async (userId: string): Promise<string | null> => {
    try {
      const response = await fetch('/api/generateToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch token');
      }

      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error('Error fetching chat token:', error);
      return null;
    }
  };

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        const user = await fetchUserInfos();
        localStorage.setItem('userId', user?.id.toString());

        const chatToken = await getChatToken(user?.id.toString());

        if (chatToken && user) {
          await client.connectUser(
            {
              id: user?.id.toString(),
              name: user?.firstname.toString() ?? '',
              image: getPictureToDisplay(user),
            },
            chatToken
          );
        }

        await dispatch({
          type: Types.INITIAL,
          payload: {
            user,
            chatToken: chatToken ?? '',
          },
        });
      } else {
        await dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            chatToken: '',
          },
        });
      }
    } catch (error) {
      console.error(error);
      await dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          chatToken: '',
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      throw error;
    }

    posthog.capture('user_logged_in');

    const accessToken = data.session?.access_token as string;
    await setSession(accessToken);
    const user = await fetchUserInfos();
    posthog.identify(`${user.id}`, {
      email: user.email,
      name: `${user.firstname} ${user.lastname}`,
    });
    posthog.capture('user_logged_in');
    await setSession(accessToken);

    Cookies.set('authToken', accessToken, { expires: 1, secure: true, sameSite: 'Lax' }); // Adjust expiration as needed

    localStorage.setItem('userId', user?.id.toString());
    const chatToken = await getChatToken(user?.id.toString());

    if (chatToken) {
      await client.connectUser(
        {
          id: user?.id.toString(),
          name: user?.firstname.toString() ?? '',
          image: getPictureToDisplay(user),
        },
        chatToken
      );
    }

    await dispatch({
      type: Types.LOGIN,
      payload: {
        user,
        chatToken: chatToken ?? '',
      },
    });
    await handleDeviceToken(user);
  }, []);

  const sendRequest = useCallback(async (phone: string, isSocialLogin?: boolean) => {
    const response = await sendPhoneVerificationCode({ phone }, isSocialLogin);

    await dispatch({
      type: Types.PHONE,
      payload: {
        phone,
      },
    });

    return response;
  }, []);

  const registerInfo = useCallback(async (email: string, password: string, phone: string) => {
    const data = {
      email,
      phone,
      password,
    };

    const response = await axios.post(endpoints.auth.registerInfo, data);
    const { user, accessToken } = response.data;
    setSession(accessToken);
    Cookies.set('authToken', accessToken, { expires: 1, secure: true, sameSite: 'Lax' }); // Adjust expiration as needed
    const chatToken = await getChatToken(user?.id.toString());

    if (chatToken) {
      await client.connectUser(
        {
          id: user?.id.toString(),
          name: user?.firstname.toString() ?? '',
          image: getPictureToDisplay(user),
        },
        chatToken
      );
    }
    await dispatch({
      type: Types.REGISTER,
      payload: {
        user,
        chatToken: chatToken ?? '',
      },
    });

    const userInfos = await fetchUserInfos();
    posthog.capture('user_signed_up', { userId: userInfos.id });
    posthog.identify(`${user.id}`, {
      email: user.email,
      name: `${user.firstname} ${user.lastname}`,
    });
    await handleDeviceToken(userInfos);
  }, []);

  const updateProfile = useCallback(
    async (data: any) => {
      const response = await axios.post(endpoints.auth.updateInfo, data);
      const chatToken = await getChatToken(state?.user?.id.toString() ?? '');

      await dispatch({
        type: Types.REGISTER,
        payload: {
          user: response.data,
          chatToken: chatToken ?? '',
        },
      });
    },
    [state]
  );

  const updateProfilePicture = useCallback(
    async (data: any) => {
      const response = await axios.put(endpoints.auth.updatePictures, data);
      const chatToken = await getChatToken(state?.user?.id.toString() ?? '');
      await dispatch({
        type: Types.REGISTER,
        payload: {
          user: response.data,
          chatToken: chatToken ?? '',
        },
      });
    },
    [state]
  );

  const verifyPhone = useCallback(async (phone: string, code: string, isSocialLogin?: boolean) => {
    await verifyPhoneCode({ phone, code },isSocialLogin);
    posthog.capture('phone_verified');

    await dispatch({
      type: Types.VERIFYPHONE,
      payload: {
        phoneVerified: true,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const response = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = response.data;

      localStorage.setItem(STORAGE_KEY, accessToken);
      Cookies.set('authToken', accessToken, { expires: 1, secure: true, sameSite: 'Lax' }); // Adjust expiration as needed

      await dispatch({
        type: Types.REGISTER,
        payload: {
          user,
          chatToken: '',
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    await supabase.auth.signOut();
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
    Cookies.remove('authToken'); // Adjust expiration as needed
    posthog.reset();
  }, []);

  const checkAuthenticated = state.user && state.user.id ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      chatToken: state.chatToken,
      method: 'jwt',
      phone: state.phone,
      phoneVerified: state.phoneVerified,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      sendRequest,
      registerInfo,
      verifyPhone,
      updateProfile,
      updateProfilePicture,
      //
      login,
      register,
      logout,
    }),
    [
      state,
      status,
      sendRequest,
      registerInfo,
      updateProfilePicture,
      updateProfile,
      verifyPhone,
      login,
      register,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
