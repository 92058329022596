import { createBrowserClient } from '@supabase/ssr';
import { SupabaseClient } from '@supabase/supabase-js';
import { useState } from 'react';

let globalInstance: SupabaseClient | null = null;

export function useSupabase() {
  const [supabase] = useState<SupabaseClient>(() => {
    if (globalInstance) {
      return globalInstance;
    }

    if (!process.env.NEXT_PUBLIC_SUPABASE_URL || !process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY) {
      throw new Error('Missing Supabase environment variables');
    }

    globalInstance = createBrowserClient(
      process.env.NEXT_PUBLIC_SUPABASE_URL,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
    );

    return globalInstance;
  });

  return supabase;
}
