import axios from 'axios';

import { UpsertDeviceTokenDTO, User } from '../types';
import { axiosInstance, endpoints } from '../utils/axios';

export async function exists(email: string): Promise<boolean> {
  const response = await axios.get<boolean>(endpoints.user.exists, { params: { email } });
  return response.data;
}

export async function upsertDevicePushToken(payload: UpsertDeviceTokenDTO) {
  const response = await axiosInstance.post(endpoints.devicePushTokens.upsert, payload);
  return response.data;
}

export async function fetchUserInfos(): Promise<User> {
  const { data } = await axiosInstance.get(endpoints.auth.me);
  return data;
}
