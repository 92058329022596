'use client';

import { PropsWithChildren } from 'react';
import { isValidToken } from 'src/auth/context/jwt/utils';
import { useAuthContext } from 'src/auth/hooks';
import { LoadingSplashScreen } from 'src/components/loading-screen';
import { Chat, useCreateChatClient } from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';

const apiKey: string = 'y7fsjd33c926';

export default function AblyRealtimeProvider({ children }: PropsWithChildren) {
  const { user, chatToken } = useAuthContext();

  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: chatToken ?? '',
    userData: { id: user?.id?.toString() || '' }, // Provide an empty string as a fallback
  });

  if (!client || !user?.id) return <LoadingSplashScreen />;

  if (!isValidToken(chatToken)) {
    return children;
  }

  return (
    <Chat client={client} theme="str-chat__theme-dark">
      {children}
    </Chat>
  );
}
